<template>
  <div ref="indexContent" class="home-container" :style="{ height: $store.state.themeInfo.type === 1 ? '100%' : '', overflowX: 'hidden' }">
    <template v-if="$store.state.themeInfo.type === 2">
      <head-menu />
      <live-content />
      <!-- <daka-content /> -->
      <news-content />
      <video-content />
    </template>
    <template v-else-if="$store.state.themeInfo.type === 1">
      <head-menu />
    </template>
  </div>
</template>

<script>
import headMenu from './components/headMenu3'
import newsContent from './components/newsContent'
import videoContent from './components/videoContent'
import liveContent from './components/liveContent'
// import dakaContent from './components/dakaContent'
export default {
  name: 'home',
  components: { headMenu, liveContent, newsContent, videoContent }
}
</script>

<style lang="less" scoped>
.home-container{
  position: relative;
  // background: linear-gradient(to top, rgb(16, 24, 63), rgb(15, 40, 99)) url('~@/assets/images/home-bg.png') center/100% auto no-repeat;
  // background: rgb(16, 24, 63);
  // background-color: #1c3876;
  background: url('https://sn202108-1305501521.cos.ap-shanghai.myqcloud.com/202203141034252028044.jpg') no-repeat;
  // background-position-y: 180px;
  background-size: 100% 100%;
  // &::before{
  //   content: '';
  //   width: 100%;
  //   position: fixed;
  //   background: url('~@/assets/images/home-bg.png') center/120% 200px no-repeat;
  //   left: 0;
  //   top: 200px;
  //   height: 300px;
  // }
  // &::after{
  //   content: '';
  //   width: 100%;
  //   position: fixed;
  //   background: url('~@/assets/images/home-bg.png') center/120% 200px no-repeat;
  //   left: 0;
  //   bottom: -30px;
  //   height: 300px;
  // }
}
</style>