<template>
  <div class="banner-container" ref="container">
    <img
      class="bg"
      src="https://sn202108-1305501521.cos.ap-shanghai.myqcloud.com/202203141805152971834.gif"
      alt=""
    />
    <div
      class="active-logo"
      :style="{ marginTop: isArrangePage ? '0' : '-45px' }"
    >
      <!-- <img
        src="https://zs202010-1300602059.cos.ap-guangzhou.myqcloud.com/202104152218310739777.png"
        alt=""
      /> -->
      <div class="cloud"></div>
    </div>
    <img
      class="logo"
      src="https://zs202010-1300602059.cos.ap-guangzhou.myqcloud.com/202203091835178896735.png"
      alt=""
    />
    <canvas id="canvas" ref="canvas"></canvas>
  </div>
</template>

<script>
export default {
  name: "banner",
  props: {
    active: {
      type: Boolean,
      default: true,
    },
  },
  mounted() {
    if (this.active) {
      this.$nextTick(() => {
        this.initCanvas();
      });
    }
  },
  computed: {
    isArrangePage() {
      return this.$route.path.includes("arrangeDay");
    },
  },
  methods: {
    initCanvas() {
      var canvas = document.getElementById("canvas"),
        ctx = canvas.getContext("2d"),
        w = (canvas.width = this.$refs.container.clientWidth),
        h = (canvas.height = this.$refs.container.clientHeight),
        hue = 500,
        stars = [],
        count = 0,
        maxStars = 1200;

      // Thanks @jackrugile for the performance tip! http://codepen.io/jackrugile/pen/BjBGoM
      // Cache gradient
      var canvas2 = document.createElement("canvas"),
        ctx2 = canvas2.getContext("2d");
      canvas2.width = 100;
      canvas2.height = 100;
      var half = canvas2.width / 2,
        gradient2 = ctx2.createRadialGradient(half, half, 0, half, half, half);
      gradient2.addColorStop(0.025, "#fff");
      gradient2.addColorStop(0.1, "hsl(" + hue + ", 61%, 33%)");
      gradient2.addColorStop(0.25, "hsl(" + hue + ", 64%, 6%)");
      gradient2.addColorStop(1, "transparent");

      ctx2.fillStyle = gradient2;
      ctx2.beginPath();
      ctx2.arc(half, half, half, 0, Math.PI * 2);
      ctx2.fill();

      // End cache

      function random(min, max) {
        if (arguments.length < 2) {
          max = min;
          min = 0;
        }

        if (min > max) {
          var hold = max;
          max = min;
          min = hold;
        }

        return Math.floor(Math.random() * (max - min + 1)) + min;
      }

      var Star = function () {
        this.orbitRadius = random(w / 2 - 50);
        this.radius = random(100, this.orbitRadius) / 10;
        this.orbitX = w / 2;
        this.orbitY = h / 2;
        this.timePassed = random(0, maxStars);
        this.speed = random(this.orbitRadius) / 100000;
        this.alpha = random(2, 10) / 10;

        count++;
        stars[count] = this;
      };

      Star.prototype.draw = function () {
        var x = Math.sin(this.timePassed + 1) * this.orbitRadius + this.orbitX,
          y = (Math.cos(this.timePassed) * this.orbitRadius) / 2 + this.orbitY,
          twinkle = random(10);

        if (twinkle === 1 && this.alpha > 0) {
          this.alpha -= 0.05;
        } else if (twinkle === 2 && this.alpha < 1) {
          this.alpha += 0.05;
        }

        ctx.globalAlpha = this.alpha;
        ctx.drawImage(
          canvas2,
          x - this.radius / 2,
          y - this.radius / 2,
          this.radius,
          this.radius
        );
        this.timePassed += this.speed;
      };

      for (var i = 0; i < maxStars; i++) {
        new Star();
      }

      function animation() {
        ctx.globalCompositeOperation = "source-over";
        ctx.globalAlpha = 0.1;
        ctx.fillStyle = "hsla(" + hue + ", 0%, 1%, 1)";
        ctx.fillRect(0, 0, w, h);

        ctx.globalCompositeOperation = "lighter";
        for (var i = 1, l = stars.length; i < l; i++) {
          stars[i].draw();
        }

        window.requestAnimationFrame(animation);
      }

      animation();
    },
  },
};
</script>

<style lang="less" scoped>
.banner-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 4.8rem;
  background: #ccc;
  .bg {
    position: absolute;
    height: 100%;
    width: 100%;
    object-fit: fill;
  }
  .logo {
    position: absolute;
    left: 5px;
    top: 5px;
    height: 37px;
  }
  .active-logo {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    z-index: 2;
    img {
      animation: act 15s ease infinite;
      z-index: 2;
      height: 30px;
    }
    // img:nth-of-type(1){
    //   height: 34px;
    // }
    // img:nth-of-type(2){
    //   height: 13px;
    //   // animation-delay: 3s;
    // }
    @keyframes act {
      10% {
        transform: translate(20px, 5px);
      }
      20% {
        transform: translate(1px, 0px);
      }
      40% {
        transform: translate(0px, 10px);
      }
      60% {
        transform: translate(20px, 10px);
      }
      70% {
        transform: translate(-5px, 8px);
      }
      80% {
        transform: translate(5px, -2px);
      }
      100% {
        transform: translate(0, 0);
      }
    }
    .cloud {
      position: absolute;
      height: 50px;
      width: 200px;
      background: rgba(255, 255, 255, 0.5);
      filter: blur(40px);
      z-index: 1;
      animation: fade 5s linear infinite;
    }
    @keyframes fade {
      50% {
        transform: translate(2px, -1px);
        background: rgba(255, 255, 255, 0.8);
      }
      100% {
        transform: translate(0, 0);
        background: rgba(255, 255, 255, 0.5);
      }
    }
  }
  #canvas {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    width: 100vw;
    opacity: 0.2;
  }
}
</style>
